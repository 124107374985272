<template>
    <div>
        <CustomDataTable :table-title="tableTitle" :params="params" :filters="filters" :table-fields="tableFields" v-on:show-data-modal="showDataRowModal" ref="table"  :buttons="buttons"></CustomDataTable>
        <DataRowModal :dialog="dialog" :data="rowData" @hide-data-modal="hideDataRowModal" type="sb" @delete-data-row-sb="deleteDataRow" :fields="rowFields"  ></DataRowModal>
    </div>
</template>

<script>
    import CustomDataTable from "../../../components/table/CustomDataTable";
    import DataRowModal from "../../../components/table/DataRowModal";
    import Vue from "vue";
    export default {
        name: "ShippingBills_view",
        components: {DataRowModal, CustomDataTable},
        data: ()=>({
            tableTitle: 'Shipping Bills',
            params:{
                Type: 'sb',
                Skip: 0,
                Take: 10,
            },
            filters:[
                { key: 'BeNo', label: 'Shipping BIll No', value: null, type: 'number' },
                { key: 'DateFrom', label: 'Date From', value: null, type: 'date' },
                { key: 'DateTo', label: 'Date From', value: null, type: 'date' },
                { key: 'Bin', label: 'Bin', value: null, type: 'number' },
                { key: 'InvoiceNo', label: 'Invoice', value: null, type: 'number' },
                { key: 'TinNo', label: 'Tin', value: null, type: 'number' },
                { key: 'ExpNo', label: 'EXP', value: null, type: 'number' },
                { key: 'ConNamAdr', label: 'Exporter', value: null, type: 'string' },
            ] ,
            tableFields: [
                {key: 'Id', label: 'ID', type: 'number'},
                {key: 'BeNo', label: 'Shipping Bill No', type: 'number'},
                {key: 'BeDate', label: 'Shipping Bill Date', type: 'date'},
                {key: 'ConNamAdr', label: 'Exporter', type: 'string'},
                {key: 'Bin', label: 'Bin', type: 'number', searchable: true},
                {key: 'InvoiceValue', label: 'Invoice Value', type: 'number'},
                {key: 'TinNo', label: 'Tin No', type: 'number'},
                {key: 'ExpNo', label: 'Exp No', type: 'number'},
                {key: 'CityDest', label: 'Country', type: 'string'},
                {key: 'HsCode', label: 'Hs Code', type: 'string'},
                {key: 'Qty', label: 'Qty', type: 'string'},
                {key: 'NetWtKg', label: 'Net Wt Kg', type: 'string'},
            ],

            dialog: false,
            rowData: {},
            rowFields: [
                {key: 'Id', label: 'ID', hasChild: false, type: 'string'},
                {key: 'BeNo', label: 'Shipping Bill No', hasChild: false, type: 'string'},
                {key: 'BeDate', label: 'Shipping Bill Date', hasChild: false, type: 'date'},
                {key: 'ExpNo', label: 'Exp No', hasChild: false, type: 'string'},
                {key: 'TinNo', label: 'Tin No', hasChild: false, type: 'string'},
                {key: 'Bin', label: 'Bin No', hasChild: false, type: 'string'},
                {key: 'ConNamAdr', label: 'Exporter', hasChild: false, type: 'string'},
                {key: 'CityDest', label: 'Country', hasChild: false, type: 'string'},
                {key: 'DelTerms', label: 'Terms', hasChild: false, type: 'string'},
                {key: 'Currency', label: 'Currency', hasChild: false, type: 'string'},
                {key: 'ExcRat', label: 'Exchange Rate', hasChild: false, type: 'string'},
                {key: 'NatureTran', label: 'Nature of Transaction', hasChild: false, type: 'string'},
                {key: 'Office', label: 'Port', hasChild: false, type: 'string'},
                {key: 'Lc', label: 'LC No', hasChild: false, type: 'string'},
                {key: 'HsCode', label: 'Hs Code', hasChild: false, type: 'string'},
                {key: 'NetWtKg', label: 'Net Weight Kg', hasChild: false, type: 'string'},
                {key: 'Qty', label: 'Quantity', hasChild: false, type: 'string'},
                {key: 'AssValue', label: 'Assessment Value', hasChild: false, type: 'string'},
                {key: 'InvoiceNo', label: 'Invoice No', hasChild: false, type: 'string'},
                {key: 'InvoiceValue', label: 'Invoice Value', hasChild: false, type: 'string'},
                {key: 'ModTrs', label: 'Mod Trs', hasChild: false, type: 'string'},
                {key: 'NoPkg', label: 'No Of Pkg', hasChild: false, type: 'string'},
                {key: 'PkgCod', label: 'Pkg Cod', hasChild: false, type: 'string'},
                {key: 'Description', label: 'Description', hasChild: false, type: 'string'},
                {key: 'QtyCode', label: 'Qty Code', hasChild: false, type: 'string'},
                {key: 'ItemNBR', label: 'Item NBR', hasChild: false, type: 'string'},
            ],

            buttons: []
        }),
        methods:{
            showDataRowModal: function (payload) {
                console.log(payload);
                if(payload.type === 'sb') {
                    this.rowData = payload.data;
                    this.dialog = true;
                }
            },
            hideDataRowModal: function () {
                this.dialog = false;
            },
            deleteDataRow: function (data) {
                this.dialog = false;

                this.$axios.post(Vue.prototype.$globalSettings.api.endpoints.action.deleteData, { Type: this.params.Type, ExpId: null, SBId: data.Id })
                    .then(async (response) => {
                        console.log(response.data);
                        if (response.data.Status === 'success') {
                            await this.$refs.table.loadTableData();
                        }
                    })
                    .catch((error) => {
                        this.$globalHelpers.processHttpErrorsToast(error);
                    })
            },
            goToDuplicateChecker: async function () {
                await this.$router.push({name: 'App.ShippingBills.Duplicates'});
            }
        },
        created() {
            this.buttons.push({ func: this.goToDuplicateChecker, funcParam: null, label: 'Delete Duplicates', icon: 'mdi-file-document-alert' });
        }
    }
</script>

<style scoped>

</style>